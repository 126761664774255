import { defineStore } from 'pinia';
import { ref } from 'vue';

export interface PropertyType {
  permission?: boolean;
  profile?: boolean;
  rsaCache?: boolean;
  ssoMode?: boolean;
  viewMode?: string;
  sso?: {
    redirectionRootUrl?: string;
    ssoMode?: boolean;
  };
}

export const usePropertyStore = defineStore('property', () => {
  const propertyData = ref({} as PropertyType);

  return {
    propertyData,
  };
});
