import { GridColumn } from '@/common/utils';
import {
  FilterItem,
  FilterSelectedValues,
} from '@/common/components/molecules/filterSearch/filterSearch.type';
import { ComputedRef, ref, watch } from 'vue';
import { filterFunctionByOperator } from '@/common/components/molecules/filterSearch/filterSearch.utils';

export const useEvGridFilterSearch = <Row extends unknown>({
  columns,
  filterItems,
}: {
  columns: ComputedRef<GridColumn[]>;
  filterItems: FilterItem[];
}) => {
  const filterSearchResultMV = ref<FilterSelectedValues>();

  const commonFilterCondition = (
    row: Row,
    token: {
      key: string;
      operator: string;
      value: string;
    },
    isTreeGrid: boolean,
  ) => {
    const targetColumnIndex = columns?.value?.findIndex((column) => column.field === token.key);
    const { type } = columns?.value?.[targetColumnIndex];
    const multi = filterItems?.find((item) => item.key.id === token.key)?.values?.multi;
    const searchValues: string[] = multi ? token.value.split(',') : [token.value];
    const targetValue = isTreeGrid ? row[token.key] : row[targetColumnIndex];

    return filterFunctionByOperator({
      targetValue,
      operator: token.operator,
      searchValues,
      type,
    });
  };

  const filterGridByFilterSearch = (rows, result: FilterSelectedValues | undefined) => {
    let filterResult: any[] = rows;

    if (result?.tokens.length) {
      filterResult = filterResult.filter((row) => {
        return result?.logicalOperator === 'AND'
          ? result.tokens.every((token) => commonFilterCondition(row, token, false))
          : result.tokens.some((token) => commonFilterCondition(row, token, false));
      });
    }

    return filterResult;
  };

  const filterTreeGridByFilterSearch = (rows, result: FilterSelectedValues | undefined) => {
    const filterTree = (data) => {
      return data
        .map((node) => {
          const show =
            result?.logicalOperator === 'AND'
              ? result.tokens.every((token) => commonFilterCondition(node, token, true))
              : result?.tokens.some((token) => commonFilterCondition(node, token, true));

          const children = node.children ? filterTree(node.children) : undefined;

          return {
            ...node,
            show: show || children?.some((child) => child.show),
            children,
          };
        })
        .filter((node) => node.show);
    };

    if (!result?.tokens.length) {
      return rows.map((node) => ({ ...node, show: true }));
    }

    return filterTree(rows);
  };

  return {
    filterSearchResultMV,
    filterGridByFilterSearch,
    filterTreeGridByFilterSearch,
  };
};
