<script lang="ts" setup>
import { useViewModeStore } from '@/common/stores/view-mode';

const { productName } = useViewModeStore();
</script>

<template>
  <header class="app-header">
    <h2 :class="['app-view-name', { 'app-view-name--has-route': !!$slots.route }]">
      <slot name="title">
        <span class="view-name">
          {{ productName }}
        </span>
      </slot>
    </h2>
    <div class="app-additional-info">
      <slot />
    </div>
  </header>
</template>

<style lang="scss">
.app-view-name {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 56px 0 20px;
  font-size: 18px;
  line-height: 1;
  color: var(--app-header-font-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  &--has-route {
    padding-left: 32px;
  }

  .path-name {
    position: relative;
    margin-right: 12px;
    font-weight: 400;
    color: var(--app-header-path-font-color);

    + .path-name {
      margin-left: 4px;
    }

    &::after {
      position: absolute;
      top: 50%;
      right: -12px;
      font-weight: 300;
      color: var(--app-header-path-divider-font-color);
      transform: translateY(-50%);
      content: '/';
    }
  }

  .view-name {
    margin-left: 8px;
    color: var(--app-header-font-color);
  }
}
.app-additional-info {
  display: flex;
  flex: 1;
  align-items: center;
  .header-selectbox__label,
  .header-label {
    display: flex;
    align-items: center;
    width: max-content;
    padding-right: 8px;
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;

    &:first-child::before {
      display: block;
      width: 1px;
      height: 20px;
      margin-right: 16px;
      background: var(--color-d-gray-07);
      content: '';
    }
  }
  .header-selectbox_input,
  .header-select-box {
    $selectbox-height: 36px;

    width: 180px !important;
    margin-right: 20px;
    .ev-input {
      height: $selectbox-height;
      border: none !important;
      background-color: #272525;
      transition: background-color $animate-base;
      &:focus,
      &:hover {
        background-color: #181818;
      }
    }
    .ev-select-dropbox {
      border-color: #272525;
      background-color: #272525;
    }
    .ev-select-dropbox-item {
      height: $selectbox-height;
      background-color: #272525;
      font-size: $font-size-base;
      &.selected {
        background-color: #880e4f;
      }
    }
  }
}

@include max-screen() {
  .app-view-name {
    font-size: 16px;
  }
  .header-label {
    font-size: 14px;
  }
}
</style>
