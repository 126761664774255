import HelpViewport from '@/common/components/templates/HelpViewport.vue';
import { i18n } from '@/common/locale';
import { requireAuth } from '@/common/router/navigationGuard';
import { downloadByUrl } from '@/common/utils/commonUtils';
import { RouteRecordRaw } from 'vue-router';

const PREFIX_HELP = 'help_';

const helpMenu: RouteRecordRaw[] = [
  {
    path: '/tutorial/',
    name: `${PREFIX_HELP}Tutorial`,
    component: HelpViewport,
    beforeEnter: requireAuth,
    children: [
      {
        path: 'dashboard',
        name: `${PREFIX_HELP}Dashboard`,
        component: () =>
          import(
            /* webpackChunkName: "help-tutorial-dashboard" */ '../views/tutorial/TutorialView.vue'
          ),
      },
      {
        path: 'infrastructure',
        name: `${PREFIX_HELP}Infrastructure`,
        component: () =>
          import(
            /* webpackChunkName: "help-tutorial-infrastructure" */ '../views/tutorial/TutorialView.vue'
          ),
      },
      {
        path: 'cloud',
        name: `${PREFIX_HELP}Cloud`,
        component: () =>
          import(
            /* webpackChunkName: "help-tutorial-cloud" */ '../views/tutorial/TutorialView.vue'
          ),
      },
      {
        path: 'kubernetes',
        name: `${PREFIX_HELP}Kubernetes`,
        component: () =>
          import(
            /* webpackChunkName: "help-tutorial-kubernetes" */ '../views/tutorial/TutorialView.vue'
          ),
      },
      {
        path: 'application',
        name: `${PREFIX_HELP}Application`,
        component: () =>
          import(
            /* webpackChunkName: "help-tutorial-application" */ '../views/tutorial/TutorialView.vue'
          ),
      },
      {
        path: 'database',
        name: `${PREFIX_HELP}Database`,
        component: () =>
          import(
            /* webpackChunkName: "help-tutorial-database" */ '../views/tutorial/TutorialView.vue'
          ),
      },
      {
        path: 'analysis',
        name: `${PREFIX_HELP}Analysis`,
        component: () =>
          import(
            /* webpackChunkName: "help-tutorial-analysis" */ '../views/tutorial/TutorialView.vue'
          ),
      }, // NOTE: 메뉴 숨김
    ],
  },
  // {
  //   path: '/support/',
  //   name: `${PREFIX_HELP}HELP.MANUAL`,
  //   children: [
  //     // 준비중 이라 스펙에서 제외
  //     // {
  //     //   path: 'faq',
  //     //   name: `${PREFIX_HELP}HELP.FAQ`,
  //     //   redirect: '',
  //     // },
  //     {
  //       path: 'product-introduction',
  //       name: `${PREFIX_HELP}HELP.PRODUCT_INTRODUCTION_DOWNLOAD`,
  //       beforeEnter: (to, from, next) => {
  //         const { t, locale } = i18n.global;
  //         downloadByUrl(
  //           `/support/${locale.value}/product_introduction.pdf`,
  //           `exemONE_${t('WORD.PRODUCT_INTRODUCTION')}.pdf`,
  //         );
  //         next(false);
  //       },
  //       children: [],
  //       meta: { icon: 'icon-download' },
  //     },
  //     {
  //       path: 'user-guide',
  //       name: `${PREFIX_HELP}HELP.USER_GUIDE_DOWNLOAD`,
  //       beforeEnter: (to, from, next) => {
  //         const { t, locale } = i18n.global;
  //         downloadByUrl(
  //           `/support/${locale.value}/user_guide.pdf`,
  //           `exemONE_${t('WORD.USER_GUIDE')}.pdf`,
  //         );
  //         next(false);
  //       },
  //       children: [],
  //       meta: { icon: 'icon-download' },
  //     },
  //   ],
  // },
  {
    path: '/guide/',
    name: `${PREFIX_HELP}HELP.GUIDE`,
    children: [
      {
        path: 'openapi-guide',
        name: `${PREFIX_HELP}HELP.OPENAPI_GUIDE`,
        beforeEnter: (to, from, next) => {
          const { t } = i18n.global;
          downloadByUrl(
            '/support/exemOne_openapi_guide.pdf',
            `exemONE_${t('WORD.OPENAPI_GUIDE')}.pdf`,
          );
          next(false);
        },
        children: [],
        meta: { icon: 'icon-download' },
      },
    ],
  },
];

const getHelpNaviMenu = () => [...helpMenu];

export { helpMenu, getHelpNaviMenu };
