import { defineStore } from 'pinia';
import { computed, Ref, ref, watch } from 'vue';
import { getCommonAlertCountAlertCommonControllerAxios } from '@/openapi/alert/api/alert-common-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { useRepeat } from '@/worker/composables/useRepeat';
import { useAbortApi } from '@/common/utils/apiUtils';
import router from '@/common/router';

const useAlertCount = (businessGroupIds: Ref<string[]>) => {
  const alertCount = ref<number>(0);

  const { getSignal, abortApi } = useAbortApi();
  const fetchAlertCount = async () => {
    try {
      abortApi();
      const { data } = await getCommonAlertCountAlertCommonControllerAxios({
        targetKind: 'BUSINESS_SERVICE_GROUP',
        subTargetKinds: ['database', 'application'],
        targetIds: businessGroupIds.value,
        signal: getSignal(),
        frameName: FRAME_NAMES.DASHBOARD.ALERT_COUNT,
      });
      const { criticalCount, warningCount } = data?.data ?? {};
      alertCount.value = (criticalCount ?? 0) + (warningCount ?? 0);
    } catch (e) {
      console.log(e);
    }
  };

  const { resetFetch, clearFetch } = useRepeat(fetchAlertCount, 5_000, {
    isSetup: false,
    isImmediate: false,
  });

  return {
    alertCount,
    resetAlertCountFetch: resetFetch,
    clearAlertCountFetch: clearFetch,
  };
};

export const useBusinessAlertSlideStore = defineStore('BusinessAlertSlide', () => {
  const isShowAlertDetail = ref(false);
  const businessGroupIds = ref<string[]>([]);

  const isBusinessMap = computed(() => {
    return router.currentRoute.value.path.includes('businessMap');
  });

  const { alertCount, resetAlertCountFetch, clearAlertCountFetch } =
    useAlertCount(businessGroupIds);

  const setGroutIds = (ids: string[]) => {
    businessGroupIds.value = ids;
  };

  const onClickAlertIcon = () => {
    isShowAlertDetail.value = true;
    clearAlertCountFetch();
  };

  watch(
    () => [businessGroupIds.value, isShowAlertDetail.value],
    ([, isShowDetail]) => {
      if (!isShowDetail && isBusinessMap.value) {
        resetAlertCountFetch();
      }
    },
    { immediate: true },
  );

  return {
    isShowAlertDetail,
    alertCount,
    targetIds: businessGroupIds,
    setGroutIds,
    clearAlertCountFetch,
    onClickAlertIcon,
  };
});
