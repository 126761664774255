<script setup lang="ts">
import { Props, Emit, setup } from './setupDashBoardPopup.setup';

const emit = defineEmits<Emit>();

const props = withDefaults(defineProps<Props>(), {
  envKey: '',
  isAlert: false,
  isShow: false,
});

const {
  setupDashBoardData,
  onClickSave,
  isValid,
  searchFilterList,
  clickOutside,
  vClickOutside,
  searchValue,
  errorMsg,
  validateName,
  checkedList,
  isPageLoading,
  t,
} = setup(props, emit);
</script>

<template>
  <div
    v-if="props.isShow"
    v-click-outside="clickOutside"
    :class="['setup-modal', props.isAlert ? 'setup-modal__alert' : '']"
  >
    <div class="setup-modal__header">
      <span>Change button name </span>
      <ev-text-field
        v-model.trim="setupDashBoardData.name"
        :max-length="10"
        :error-msg="errorMsg.name"
        @input="validateName"
      />
    </div>
    <div class="setup-modal__content">
      <span class="body-title">Select Dashboards </span>
      <div class="dashboard-list">
        <ev-text-field
          v-model="searchValue"
          :placeholder="t('WORD.SEARCH')"
          type="search"
          class="search-input base-expansion__search"
        />
        <ev-loading :model-value="isPageLoading" />
        <div class="dashboard-list__check-area">
          <div
            v-for="(list, idx) in searchFilterList"
            :key="idx"
            :class="['base-expansion__list']"
          >
            <ev-checkbox
              v-model="checkedList"
              v-model:value="list.dashboardId"
              :label="list.dashboardId"
              :title="list.dashboardName"
            >
              {{ list.dashboardName }}
            </ev-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!setupDashBoardData.urlYN"
      class="setup-modal__urlN"
    >
      <ev-checkbox
        v-model="setupDashBoardData.urlYN"
        label="URL"
      />
    </div>
    <div
      v-else
      class="setup-modal__urlY"
    >
      <ev-checkbox
        v-model="setupDashBoardData.urlYN"
        label="URL"
      />
      <div>
        <span> Name </span>
        <ev-text-field v-model="setupDashBoardData.urlName" />
      </div>
      <div>
        <span>
          URL
          <span class="required">*</span>
        </span>
        <ev-text-field v-model="setupDashBoardData.url" />
      </div>
    </div>
    <div class="setup-modal__btn">
      <ev-button
        type="primary"
        :disabled="!isValid"
        @click="onClickSave"
      >
        {{ t('WORD.OK') }}
      </ev-button>
    </div>
  </div>
</template>

<style lang="scss">
.setup-modal {
  display: flex;
  position: relative;
  left: 20px;
  z-index: 1;
  flex-direction: column;
  gap: 8px;
  width: 240px;
  border: var(--popup-dashboard-border-color);
  border-radius: 2px;
  background: var(--popup-dashboard-bg-color);
  box-shadow: var(--popup-dashboard-shadow);
  &__alert {
    display: flex;
    position: absolute;
    top: 76px;
    right: 25px;
    left: auto;
    z-index: 1;
    flex-direction: column;
    gap: 8px;
    width: 240px;
    border: var(--popup-dashboard-border-color);
    border-radius: 2px;
    background: var(--popup-dashboard-bg-color);
    box-shadow: var(--popup-dashboard-shadow);
  }

  .ev-text-field {
    width: 100% !important;
  }
  &__header {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
    justify-content: center;
    height: 60px;
    padding: 8px 10px;
    border: var(--popup-dashboard-header-border-color);
    background: var(--popup-dashboard-header-bg-color);
  }
  &__content {
    display: flex;
    flex-direction: column;
    .body-title {
      margin: 8px;
      font-size: 12px;
    }
    .dashboard-list {
      margin: 0 8px;
      border: 1px solid var(--popup-dashboard-border-color);
      &__check-area {
        height: 190px;
        padding: 8px;
        overflow-y: auto;
        .ev-checkbox {
          padding: 8px;
        }
        .base-expansion__list {
          display: flex;
          align-items: center;
          height: 28px;
          cursor: pointer;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .ev-text-field {
        padding: 8px;
      }
    }
  }
  &__urlN {
    padding: 8px;
    margin: 0 8px;
    border: 1px solid var(--popup-dashboard-header-border-color);
  }
  &__urlY {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px;
    margin: 0 8px;
    border: 1px solid var(--popup-dashboard-header-border-color);
    div {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
    .required {
      color: var(--required-color);
    }
  }
  &__btn {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 48px;
    margin: 0 8px;
  }
}
</style>
