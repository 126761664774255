import { computed, getCurrentInstance, onBeforeUnmount, ref, Ref, watch } from 'vue';
import dayjs from 'dayjs';
import { useInternational } from '@/common/locale';
import {
  DetailItemInfo,
  ItemInfo,
  StoredAlertParams,
} from '@/common/components/organisms/alertListDetail/alertListDetail.types';
import { handleResize } from '@/common/utils/browserPopupUtils';
import { useRtmApi } from '@/common/utils/apiUtils';
import {
  confirmMsg,
  showErrorMsg,
  showSuccessMsg,
  utcZeroTimeToStandardTime,
} from '@/common/utils/commonUtils';
import { clearAlertRuleControllerAxios } from '@/openapi/alert/api/alert-rule-controller-api';
import { useRepeat } from '@/worker/composables/useRepeat';
import { getAlertType, getSystemAlertInfo } from '@/alert/utils/utils';
import { clearSystemRuleControllerAxios } from '@/openapi/systemAlert/api/system-rule-controller-api';
import { AlertType } from '@/alert/components/alertDetail/alertDetail.types';
import { webStorageController } from '@/common/utils/webStorage.util';
import { TARGET_TYPE, useAlertTagsAndTargets } from '@/alert/utils/tagsAndTargets.uses';
import { DashboardAlertItems } from '@/openapi/alert/model';
import {
  getClusterTopologyAlertListAlertCommonControllerAxios,
  getCommonAlertListAlertCommonControllerAxios,
  getGroupAndRuleListAlertCommonControllerAxios,
  getK8sAlertListAlertCommonControllerAxios,
} from '@/openapi/alert/api/alert-common-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { isEmpty } from 'lodash-es';
import { useOverviewAlertStore } from '@/database/stores/overview-alert';
import { storeToRefs } from 'pinia';
import { useTargetDetailProvide } from '@/alert/components/alertTargetList/alertTargetList.uses';
import {
  useClusterTopologyAlertStore,
  useObjectTopologyAlertStore,
} from '@/config/stores/kubernetes';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { useRolePermission } from '@/common/permission/permission.utils';

export interface Props {
  type?: string; // dashboard vs. database
  isShow?: boolean;
  viewName?: string;
}
export interface Emit {
  (e: 'expand-slide', value: boolean): void;
  (e: 'openDetail'): void;
}

type RouteInfo = { type: string } & StoredAlertParams;

const POPUP_MIN_SIZE = {
  WIDTH: 1200,
  HEIGHT: 700,
};
const SYSTEM_ALERT_GROUP_ID = '0';
const USE_SLIDE_VIEW = {
  DASHBOARD: 'dashboard',
  DATABASE: 'database',
  OBJECT_TOPOLOGY: 'objectTopology',
  CLUSTER_TOPOLOGY: 'clusterTopology',
  BUSINESS: 'business',
};
const FRAME_KEY = {
  [USE_SLIDE_VIEW.DASHBOARD]: 'DASHBOARD_ALERT_DETAIL',
  [USE_SLIDE_VIEW.DATABASE]: 'DATABASE_ALERT_DETAIL',
  [USE_SLIDE_VIEW.OBJECT_TOPOLOGY]: 'K8S_OBJECT_TOPOLOGY_ALERT_DETAIL',
  [USE_SLIDE_VIEW.CLUSTER_TOPOLOGY]: 'K8S_CLUSTER_TOPOLOGY_ALERT_DETAIL',
  [USE_SLIDE_VIEW.BUSINESS]: 'BUSINESS_ALERT_DETAIL',
};

const useClearAlert = (routeInfo: Ref<RouteInfo | undefined>) => {
  const type = computed(() => routeInfo.value?.type ?? USE_SLIDE_VIEW.DASHBOARD);
  const clearController = {
    user: async (items: DetailItemInfo[]) => {
      if (!items?.length) {
        return;
      }
      const clearRequests = items.map((item) => {
        const targetsRequest = item.targetList?.map((v) => ({
          category: v.name.split(':')?.[0],
          targetId: v.id ?? '',
        }));
        return {
          alertRuleId: item.id,
          targetsRequest,
        };
      });
      await clearAlertRuleControllerAxios({
        clearRequests,
        frameName: FRAME_NAMES[FRAME_KEY[type.value]].CLEAR_ALERT_RULE,
      });
    },
    system: async (items: DetailItemInfo[]) => {
      if (!items?.length) {
        return;
      }
      const clearRequests = items.map((item) => {
        const { event, platform } = getSystemAlertInfo(item.name);
        const targetsRequest = item.targetList?.map((v) => ({
          category: v.name.split(':')?.[0],
          targetId: v.id ?? '',
        }));
        return {
          ruleId: item.id,
          systemEvent: event,
          systemKind: platform,
          targetsRequest,
        };
      });
      await clearSystemRuleControllerAxios({
        request: { clearRequests },
        frameName: FRAME_NAMES[FRAME_KEY[type.value]].CLEAR_SYSTEM_RULE,
      });
    },
  };

  return {
    clearController,
  };
};

const useAlertRule = (routeInfo: Ref<RouteInfo | undefined>) => {
  const { t } = useInternational();

  const selectedGroupId = ref(SYSTEM_ALERT_GROUP_ID);
  const selectedRuleCardId = ref('');

  const allGroupRuleData = ref<Map<string, DetailItemInfo[]>>(new Map());
  const isFetchingGroupList = ref(false);
  const groupList = ref<ItemInfo[] | undefined>();
  const ruleList = computed<DetailItemInfo[]>(
    () => allGroupRuleData.value.get(selectedGroupId.value) ?? [],
  );

  const { callApi } = useRtmApi();
  const { tagsAndTargetsController } = useAlertTagsAndTargets();

  const getConvertRuleList = (list): DetailItemInfo[] => {
    return list.map((item) => {
      const collectTime = item.lastTriggered
        ? dayjs(utcZeroTimeToStandardTime(item.lastTriggered)).format('MM-DD HH:mm:ss')
        : '';
      const type = getAlertType(item.type);
      const getIdByTagId = () => item.targets.map((v) => `${v.targetId}`).join('//');
      return {
        cardId: `${item.ruleId}//${getIdByTagId()}`,
        id: item.ruleId,
        name: item.ruleName,
        collectTime,
        targetList: tagsAndTargetsController[TARGET_TYPE.TARGETS](item.targets)?.targets,
        level: item.lastAlert.toLowerCase(),
        type,
        ruleCriteria: item.ruleCriteria,
      };
    });
  };
  const setData = (data: DashboardAlertItems[]) => {
    const alertListPropertyName = [USE_SLIDE_VIEW.DATABASE, USE_SLIDE_VIEW.BUSINESS].includes(
      routeInfo.value?.type ?? '',
    )
      ? 'alertList'
      : 'ruleList';
    data?.forEach((item) => {
      allGroupRuleData.value.set(
        item.alertGroupId ?? SYSTEM_ALERT_GROUP_ID,
        getConvertRuleList(item[alertListPropertyName]),
      );
    });
    groupList.value =
      data?.reduce<ItemInfo[]>((acc, curr) => {
        let { name } = curr;
        if (name === 'System Alert') {
          name = t('WORD.SYSTEM_ALERT');
        }
        acc.push({
          id: curr.alertGroupId ?? SYSTEM_ALERT_GROUP_ID,
          name: name ?? '',
          critical: curr.criticalCount ?? 0,
          warning: curr.warningCount ?? 0,
        });
        return acc;
      }, []) ?? [];

    const hasPrevId =
      selectedGroupId.value && data?.find((item) => item.alertGroupId === selectedGroupId.value);
    const firstId = data?.[0]?.alertGroupId ?? SYSTEM_ALERT_GROUP_ID;
    selectedGroupId.value = hasPrevId ? selectedGroupId.value : firstId;
  };

  const getApiInfo = {
    [USE_SLIDE_VIEW.DASHBOARD]: (params, frameName) => {
      return {
        fn: getGroupAndRuleListAlertCommonControllerAxios,
        frameName,
        params,
      };
    },
    [USE_SLIDE_VIEW.DATABASE]: (params, frameName) => {
      const { instanceIds, view, dbType } = params;
      const dbFrameName = `${frameName} - ${dbType} ${view}`;
      return {
        fn: getCommonAlertListAlertCommonControllerAxios,
        frameName: dbFrameName,
        params: {
          targetKind: 'DATABASE',
          subTargetKinds: ['database'],
          targetIds: instanceIds,
        },
      };
    },
    [USE_SLIDE_VIEW.OBJECT_TOPOLOGY]: (params, frameName) => {
      return {
        fn: getK8sAlertListAlertCommonControllerAxios,
        frameName,
        params,
      };
    },
    [USE_SLIDE_VIEW.CLUSTER_TOPOLOGY]: (params, frameName) => {
      return {
        fn: getClusterTopologyAlertListAlertCommonControllerAxios,
        frameName,
        params,
      };
    },
    [USE_SLIDE_VIEW.BUSINESS]: (params, frameName) => {
      return {
        fn: getCommonAlertListAlertCommonControllerAxios,
        frameName,
        params: {
          ...params,
          targetKind: 'BUSINESS_SERVICE_GROUP',
          subTargetKinds: ['database', 'application'],
        },
      };
    },
  };

  const getData = async () => {
    if (groupList.value === undefined) {
      isFetchingGroupList.value = true;
    }

    try {
      if (!routeInfo.value) {
        throw new Error('fail');
      }
      const params = routeInfo.value[routeInfo.value.type];
      const frameName = FRAME_NAMES[FRAME_KEY[routeInfo.value.type]].ALERT_GROUP_AND_RULES;

      const apiInfo = getApiInfo[routeInfo.value.type](params, frameName);

      const { data, error } = await callApi(apiInfo);
      if (!error) {
        setData(data ?? []);
      }
    } finally {
      isFetchingGroupList.value = false;
    }
  };

  const initData = () => {
    allGroupRuleData.value.clear();
    groupList.value = undefined;
  };

  return {
    selectedGroupId,
    isFetchingGroupList,
    groupList,
    selectedRuleCardId,
    ruleList,
    getData,
    initData,
  };
};

const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const overviewAlertStore = useOverviewAlertStore();
  const objectTopologyAlertStore = useObjectTopologyAlertStore();
  const clusterTopologyAlertStore = useClusterTopologyAlertStore();

  useTargetDetailProvide(emit);

  const routeInfo = ref<RouteInfo>();

  const { clearController } = useClearAlert(routeInfo);
  const {
    selectedGroupId,
    isFetchingGroupList,
    groupList,
    selectedRuleCardId,
    ruleList,
    getData,
    initData,
  } = useAlertRule(routeInfo);

  const { isPermissionDenied } = useRolePermission();

  const isBrowserPopup = computed(() => !!props.viewName);
  const isShowAlertDetail = ref(false);

  const getStorageData = () => {
    const storedDataString = webStorageController.getItem({
      type: 'session',
      key: 'alertDetailParams',
    });
    const storedData: StoredAlertParams = JSON.parse(storedDataString ?? '{}');
    return storedData;
  };

  const setRouteData = () => {
    const storageData = getStorageData();

    if (isEmpty(storageData)) {
      routeInfo.value = undefined;
      return;
    }
    routeInfo.value = {
      type: props.type ?? USE_SLIDE_VIEW.DASHBOARD,
      ...getStorageData(),
    };
  };

  const getAlertDataByStorage = async () => {
    setRouteData();
    await getData();
  };
  const { resetFetch, clearFetch } = useRepeat(getAlertDataByStorage, 5_000, {
    isSetup: [USE_SLIDE_VIEW.DASHBOARD, USE_SLIDE_VIEW.BUSINESS].includes(props.type ?? ''),
    isImmediate: false,
  });

  const showDetail = (isShow) => {
    isShowAlertDetail.value = isShow;
    emit('expand-slide', isShow);
    if (!isShow) {
      selectedRuleCardId.value = '';
    }
  };
  const onClickGroup = () => {
    showDetail(false);
  };
  const onClickRule = (item: DetailItemInfo | null) => {
    showDetail(!!item);
  };
  const onClickClear = ({ items, type }: { items: DetailItemInfo[]; type: AlertType }) => {
    const isDenied = isPermissionDenied({
      type: 'action',
      rolePermissionKey: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ALERT_LIST_CLEAR,
    });
    if (isDenied) {
      return;
    }
    confirmMsg(ctx, {
      msgStr: t('MESSAGE.CLEAR_ALERT'),
      okCallback: async () => {
        try {
          await clearController[type](items);

          switch (props.type) {
            case USE_SLIDE_VIEW.DATABASE:
              overviewAlertStore.resetFetch();
              break;
            case USE_SLIDE_VIEW.OBJECT_TOPOLOGY:
              objectTopologyAlertStore.resetFetch();
              break;
            case USE_SLIDE_VIEW.CLUSTER_TOPOLOGY:
              clusterTopologyAlertStore.resetFetch();
              break;
            case USE_SLIDE_VIEW.DASHBOARD:
            case USE_SLIDE_VIEW.BUSINESS:
            default:
              await getData();
              break;
          }

          showDetail(false);
          showSuccessMsg(ctx, t('MESSAGE.ALERT_DELETED'));
        } catch (e: any) {
          const { status } = e?.response ?? {};
          if (status !== 406) {
            showErrorMsg(ctx, t('MESSAGE.ALERT_DELETED_FAIL'));
          }
        }
      },
    });
  };

  if (isBrowserPopup.value) {
    const popupResize = () => {
      handleResize(POPUP_MIN_SIZE.WIDTH, POPUP_MIN_SIZE.HEIGHT);
    };
    window.addEventListener('resize', popupResize);

    onBeforeUnmount(() => {
      window.removeEventListener('resize', popupResize);
    });
  }

  // database > alert slide
  const { updatedFetchFlag } = storeToRefs(overviewAlertStore);
  watch(
    () => [updatedFetchFlag.value, props.isShow],
    async () => {
      if (props.type === USE_SLIDE_VIEW.DATABASE && props.isShow) {
        setRouteData();
        await getData();
      }
    },
    { immediate: true },
  );

  // object topology  > alert slide
  const { fetchFlag: objectTopologyFlag } = storeToRefs(objectTopologyAlertStore);
  watch(
    () => [objectTopologyFlag.value, props.isShow],
    async () => {
      if (props.type === USE_SLIDE_VIEW.OBJECT_TOPOLOGY && props.isShow) {
        setRouteData();
        await getData();
      }
    },
    { immediate: true },
  );

  // cluster topology  > alert slide
  const { fetchFlag: clusterTopologyFlag } = storeToRefs(clusterTopologyAlertStore);
  watch(
    () => [clusterTopologyFlag.value, props.isShow],
    async () => {
      if (props.type === USE_SLIDE_VIEW.CLUSTER_TOPOLOGY && props.isShow) {
        setRouteData();
        await getData();
      }
    },
    { immediate: true },
  );

  watch(
    () => props.isShow,
    async (isShow) => {
      if (isShow) {
        setRouteData();
        if ([USE_SLIDE_VIEW.DASHBOARD, USE_SLIDE_VIEW.BUSINESS].includes(props.type ?? '')) {
          resetFetch();
        }
      } else {
        selectedRuleCardId.value = '';
        initData();
        onClickRule(null);
        if ([USE_SLIDE_VIEW.DASHBOARD, USE_SLIDE_VIEW.BUSINESS].includes(props.type ?? '')) {
          clearFetch();
        }
      }
    },
    { immediate: true },
  );
  watch(
    (): [string, DetailItemInfo[]] => [selectedRuleCardId.value, ruleList.value],
    ([cardId, list]) => {
      if (!list?.length || !list?.find((item) => item.cardId === cardId)) {
        showDetail(false);
      }
    },
  );
  return {
    isShowAlertDetail,
    selectedGroupId,
    isFetchingGroupList,
    groupList,
    selectedRuleCardId,
    ruleList,
    onClickGroup,
    onClickRule,
    onClickClear,
    t,
  };
};

export { POPUP_MIN_SIZE, setup };
