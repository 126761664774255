import { filterChartDataByCreateType } from '@/dashboard/utils/dashboardUtils';
import { WidgetChartData, WidgetCreateType } from '@/dashboard/utils/types';
import { computed, ComputedRef, getCurrentInstance, ref, watch } from 'vue';
import { confirmMsg } from '@/common/utils/commonUtils';
import { useInternational } from '@/common/locale';
import { useStatInfoStore } from '@/common/stores/stat-info';
import { GraphsChartData } from './graphWidgetSettings/graphWidgetSettings.types';

interface Props {
  createType: WidgetCreateType;
}

interface Emits {
  (event: 'update:createType', value: WidgetCreateType): void;
}

export const useWidgetCreateType = (props: Props, emit: Emits) => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();

  const useReferenceStat = ref(props.createType === 'reference');

  watch(useReferenceStat, (newIsReferenceWidget) => {
    if (newIsReferenceWidget) {
      emit('update:createType', 'reference');
    } else {
      confirmMsg(ctx, {
        msgStr: t('MESSAGE.NOT_USE_CUSTOM_STAT'),
        okCallback: async () => {
          emit('update:createType', 'base');
        },
        cancelCallback: () => {
          useReferenceStat.value = true;
        },
      });
    }
  });

  return { useReferenceStat };
};

export const useFilteredWidgetChartData = <ChartData extends GraphsChartData | WidgetChartData>(
  widgetChartData: ComputedRef<ChartData[]>,
  createType: ComputedRef<WidgetCreateType>,
  addNewChartData: () => void,
) => {
  const filteredChartData = computed(() => {
    return widgetChartData.value.filter(({ category }) =>
      filterChartDataByCreateType(createType.value, category),
    );
  });

  watch(
    () => filteredChartData.value.length,
    (filteredChartDataLength) => {
      if (filteredChartDataLength === 0) {
        addNewChartData();
      }
    },
  );

  return { filteredChartData };
};

export const useWidgetArgumentList = () => {
  const { getStatInfo } = useStatInfoStore();

  const getArgumentList = (
    commonArguments: Readonly<string[]> | undefined,
    statId: string,
    category: string,
  ) => {
    const statInfo = getStatInfo({ statId, childCategory: category });
    if (category === 'custom') {
      return (
        statInfo?.arguments
          ?.map(({ name }) => name)
          .filter((name) => !commonArguments?.includes(name)) ?? []
      );
    }
    if (statInfo?.dataType.includes('scatter')) {
      return ['fromTime', 'toTime', 'targetIds', 'minElapsedTime', 'maxElapsedTime'].filter(
        (name) => !commonArguments?.includes(name),
      );
    }
    return ['fromTime', 'toTime', 'targetIds'].filter((name) => !commonArguments?.includes(name));
  };

  return { getArgumentList };
};
