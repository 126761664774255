<script lang="ts" setup>
import AlertGrid from '@/alert/components/alertGrid/AlertGrid.vue';
import TimePeriodIndicator from '@/common/components/molecules/timePeriodIndicator/TimePeriodIndicator.vue';
import { setup } from '@/alert/components/alertDetail/alertCurrent.setup';
import { AlertDetailTabProps } from '@/alert/components/alertDetail/alertDetail.types';
import { GLOBAL_TIME_CURRENT } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.setup';
import TextViewerWindow from '@/common/components/organisms/textViewerWindow/TextViewerWindow.vue';
import LastAlertTooltip from '@/alert/components/alertDetail/LastAlertTooltip.vue';
import { getAlertCompType } from '@/alert/components/alertGrid/alertGrid.utils';
import { USER_ENV_KEYS } from '@/common/define/userEnv.define';

const props = defineProps<AlertDetailTabProps>();

const {
  periodInfo,
  gridColumns,
  gridData,
  checkedRows,
  searchWord,
  onUpdatedIndicator,
  onClearAlert,
  onClickCell,

  isShowMessageWindow,
  eventAlertInfo,
  t,
} = setup(props);
</script>

<template>
  <section class="alert-detail-current">
    <div class="alert-current-toolbar">
      <h3 class="alert-current-toolbar__title">
        {{ t('WORD.CURRENT') }}
        <last-alert-tooltip />
      </h3>
      <div class="alert-current-toolbar__action">
        <time-period-indicator
          :period-info="periodInfo"
          :options="{
            ...GLOBAL_TIME_CURRENT,
            interval: 'I5s',
          }"
          @update-period-info="onUpdatedIndicator"
        />
        <ev-button
          size="small"
          :disabled="!checkedRows.length"
          @click="onClearAlert"
        >
          {{ t('WORD.CLEAR_ALERT') }}
        </ev-button>
      </div>
    </div>
    <div class="alert-current-grid">
      <alert-grid
        v-model:checkedRows="checkedRows"
        v-model:searchWord="searchWord"
        type="detail"
        :component-is="getAlertCompType({ ruleCriteria: props.ruleInfo.ruleCriteria })"
        :grid-columns="gridColumns"
        :grid-data="gridData"
        :options="{
          useCheckbox: true,
        }"
        class="alert-current"
        :env-key="USER_ENV_KEYS.GRIDCOLUMNS_alert_alertDetail_current"
        @on-click-cell="onClickCell"
      />
    </div>
  </section>
  <text-viewer-window
    v-model:is-show="isShowMessageWindow"
    :text="eventAlertInfo.messageText"
    is-network-alert
    :line-number="false"
    :network-info="eventAlertInfo.networkInfo"
    title="Message"
  />
</template>

<style lang="scss">
.alert-detail-current {
  height: 100%;

  .alert-current-toolbar {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    &__title {
      display: flex;
      gap: 8px;
      align-items: center;
      font-weight: 700;
    }
    &__action {
      display: flex;
      gap: 6px;
    }
  }

  .alert-current-grid {
    height: calc(100% - 36px);
  }
}
</style>
