export const DASHBOARD = {
  GRIDCOLUMNS_dashboard_scatterSummaryDetail: 'GRIDCOLUMNS_dashboard_scatterSummaryDetail',
  GRIDCOLUMNS_dashboard_scatterSummaryDetailWithStatus:
    'GRIDCOLUMNS_dashboard_scatterSummaryDetailWithStatus',
  GRIDCOLUMNS_dashboard_topologySummaryDetail: 'GRIDCOLUMNS_dashboard_topologySummaryDetail',
  GRIDCOLUMNS_dashboard_tableWidget_global: 'GRIDCOLUMNS_dashboard_tableWidget_global',
  GRIDCOLUMNS_dashboard_tableWidget_user: 'GRIDCOLUMNS_dashboard_tableWidget_user',
  GRIDCOLUMNS_dashboard_treeGridWidget_global: 'GRIDCOLUMNS_dashboard_treeGridWidget_global',
  GRIDCOLUMNS_dashboard_treeGridWidget_user: 'GRIDCOLUMNS_dashboard_treeGridWidget_user',
  SEVERITY_dashboard_hexagonWidget_global: 'SEVERITY_dashboard_hexagonWidget_global',
  SEVERITY_dashboard_hexagonWidget_user: 'SEVERITY_dashboard_hexagonWidget_user',
  POSITIONS_dashboard_topologyWidget_global: 'POSITIONS_dashboard_topologyWidget_global',
  POSITIONS_dashboard_topologyWidget_user: 'POSITIONS_dashboard_topologyWidget_user',
  AUTOALIGN_dashboard_topologyWidget_global: 'AUTOALIGN_dashboard_topologyWidget_global',
  AUTOALIGN_dashboard_topologyWidget_user: 'AUTOALIGN_dashboard_topologyWidget_user',
} as const;
