import { ref, watch } from 'vue';
import { PanelInfo, useSlideDetailStore } from '@/common/stores/slide-detail';
import { TimePeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import { getInitPeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.setup';
import { useRtmApi } from '@/common/utils/apiUtils';
import { storeToRefs } from 'pinia';
import { useInternational } from '@/common/locale';
import { useTargetTagStore } from '@/common/stores/target-tags';
import {
  getDeviceAlertMessageNdmMonitoringControllerAxios,
  getDeviceAlertNdmMonitoringControllerAxios,
} from '@/openapi/ndm/api/ndm-monitoring-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import {
  AlertTreeRow,
  useAlertRuleDetail,
  useAlertTreeGrid,
} from '@/alert/components/alertGrid/alertGrid.uses';

export interface Props {
  isShow: boolean;
}

// value를 통해 Syslog, Trap 외에 그냥 일반 Alert 값이 들어올수도 있음.
export const isEventType = (value: string | number): boolean => {
  const cellValue = `${value}`.toLowerCase();
  switch (cellValue) {
    case 'syslog':
    case 'trap':
      return true;
    default:
      return false;
  }
};

const setup = (props: Props) => {
  const { t } = useInternational();

  const slideDetailStore = useSlideDetailStore();
  const { setIsDetailLoading } = slideDetailStore;
  const { selectedSlideTabPanel: curTabInfo } = storeToRefs(slideDetailStore);
  const { callApi } = useRtmApi();

  const rows = ref<AlertTreeRow[]>([]);

  const isOpenMessageWindow = ref<boolean>(false);

  const timePeriodInfo = ref<TimePeriodInfo>({
    ...getInitPeriodInfo(),
    isPaused: !props.isShow,
  });

  const searchWord = ref('');

  const messageText = ref('');

  const networkInfo = ref<{
    facility?: string;
    severity?: string;
    specificTrapCode?: string;
    genericTrapType?: string;
    enterpriseOid?: string;
  }>({});

  const { fetchAllData: fetchAllTagsData } = useTargetTagStore();
  const { alertColumns, makeAlertTreeRows } = useAlertTreeGrid({ columnOption: 'searchable' });

  // ALERT GRID LIST API
  const onGetData = async ({ isInitialLoading = true }: { isInitialLoading?: boolean }) => {
    const { deviceId } = (curTabInfo.value as PanelInfo<'Network Device'>)?.addition;

    if (!props.isShow || !deviceId) {
      return;
    }

    setIsDetailLoading(isInitialLoading);

    const { data = [] } = await callApi({
      fn: getDeviceAlertNdmMonitoringControllerAxios,
      params: {
        deviceId,
      },
      frameName: FRAME_NAMES.NETWORK_DEVICE_DETAIL.ALERT,
    });

    rows.value = makeAlertTreeRows({ data });
    setIsDetailLoading(false);
  };

  const onRefresh = (info: TimePeriodInfo) => {
    timePeriodInfo.value = info;
    if (props.isShow && !info.isPaused) {
      onGetData({ isInitialLoading: false });
    }
  };

  const onShowMessageBox = async (item) => {
    const eventType = item.alertValue;

    if (!(eventType === 'Syslog') && !(eventType === 'Trap')) {
      return;
    }

    isOpenMessageWindow.value = true;
    timePeriodInfo.value.isPaused = true;

    const { deviceId } = (curTabInfo.value as PanelInfo<'Network Device'>)?.addition;

    const { data } = await callApi({
      fn: getDeviceAlertMessageNdmMonitoringControllerAxios,
      params: {
        deviceId,
        alertRuleId: item.alertRuleId,
        eventType,
      },
    });

    if (eventType === 'Syslog') {
      const { facility, severity, message } = data?.[0].sysLogMessage || {};
      networkInfo.value = { facility, severity };
      messageText.value = message || '';

      return;
    }

    if (eventType === 'Trap') {
      const { genericTrapType, specificTrapCode, enterpriseOid, message } =
        data?.[0].snmpTrapMessage || {};
      networkInfo.value = { genericTrapType, specificTrapCode, enterpriseOid };
      messageText.value = message || '';
      return;
    }

    messageText.value = '';
  };

  const { showAlertRuleDetail } = useAlertRuleDetail();
  const onClickCell = async ({ field, row }: { field: string; row: AlertTreeRow }) => {
    if (field === 'ruleName') {
      await fetchAllTagsData();
      showAlertRuleDetail({ info: row });
    }
    if (field === 'alertValue') {
      onShowMessageBox(row);
    }
  };

  watch(
    () => props.isShow,
    (isShow) => {
      if (isShow) {
        onGetData({ isInitialLoading: true });
        timePeriodInfo.value.isPaused = false;
        return;
      }
      timePeriodInfo.value.isPaused = true;
    },
  );

  return {
    alertColumns,
    rows,
    searchWord,
    timePeriodInfo,
    isOpenMessageWindow,
    networkInfo,
    messageText,

    onRefresh,
    onClickCell,
    t,
  };
};

export { setup };
