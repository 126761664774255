import { computed, ref } from 'vue';
import { type CurrentInstance } from '@/common/stores/instance';
import type { BreadCrumbMenuInfo, BreadCrumbMode } from '../breadCrumbs.types';
import { getLinkWrapperTop } from './breadCrumbElementLink.setup';

export interface Props {
  menuInfo?: BreadCrumbMenuInfo;
  mode?: BreadCrumbMode;
  currentInstance?: CurrentInstance;
}

export interface Emit {
  (
    e: 'instance-multi-view-expand',
    menu?: BreadCrumbMenuInfo,
    pos?: {
      top: number;
    },
  ): void;
}

const useInstanceElement = (emit: Emit) => {
  const currentMultiViewExpandedMenu = ref<string>();

  const multiViewExpand = (menu: BreadCrumbMenuInfo, evt: MouseEvent) => {
    if (currentMultiViewExpandedMenu.value === menu.value) {
      currentMultiViewExpandedMenu.value = undefined;
      emit('instance-multi-view-expand', undefined);
      return;
    }
    currentMultiViewExpandedMenu.value = menu.value;

    const top = getLinkWrapperTop(evt);
    const position = {
      top,
    };

    emit('instance-multi-view-expand', menu, position);
  };

  return {
    currentMultiViewExpandedMenu,
    multiViewExpand,
  };
};

export const setup = (props: Props, emit: Emit) => {
  const singleMenu = computed(() =>
    props.mode === 'instance'
      ? props.menuInfo?.children?.filter((m) => m.dbType !== 'redis')
      : props.menuInfo?.children?.filter((m) => !m.children?.length),
  );

  const nestedMenu = computed(() => {
    const middleMenu =
      props.mode === 'instance' ? [] : props.menuInfo?.children?.filter((m) => m.children?.length);

    return middleMenu || props.menuInfo?.subMenuList;
  });

  return {
    singleMenu,
    nestedMenu,
    ...useInstanceElement(emit),
  };
};
