export const VIEW_MODE = {
  EXEMONE: 'exemone',
  MAXGAUGE: 'maxgauge',
  INTERMAX: 'intermax',
  CLOUDMOA: 'cloudmoa',
} as const;

export const PLATFORM = {
  INFRA: 'infrastructure',
  KUBE: 'kubernetes',
  APP: 'application',
  DB: 'database',
} as const;
