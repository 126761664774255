import { checkValid } from '@/common/utils/commonUtils';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import router from '@/common/router';
import { postV1RefreshTokenAxios } from '@/openapi/meta/api/user-controller-api';
import { encrypt, webStorageController } from '@/common/utils/webStorage.util';
import { REG_EXP, STORAGE_KEY } from '@/common/utils/define';
import { setHeaderConfig } from '@/worker/commands/config/apiInstance';
import { dayjs } from 'element-plus';
import { loginUserV6ControllerAxios } from '@/openapi/metaV6/api/user-v6-controller-api';
import axios from 'axios';
import { UserLoginV6Response } from '@/openapi/metaV6/model';
import { getEncryptString, getPublicRsaKey } from '@/common/utils/rsa';

type TokenType = 'refreshToken' | 'accessToken';
type Token = Record<TokenType, string>;

const TOKEN_CHECK_CYCLE_SEC = 10 * 1_000;
const ACCESS_TOKEN_EXPIRATION_MIN = 15; // Access Token 유효 기간 (15분)
const getAccessTokenExpiredTime = () => {
  const now = dayjs();
  return +dayjs(now).add(ACCESS_TOKEN_EXPIRATION_MIN - 1, 'minute');
};
const setTokenInHeader = (accessToken: string) => {
  const tokenConfig = { key: 'Authorization', value: `Bearer ${accessToken}` };
  setHeaderConfig([tokenConfig]);
};

export const useAuthStore = defineStore('authStore', () => {
  const isVisible = ref(true);
  const expiredTimeInfo = ref<{ timer: ReturnType<typeof setTimeout> | null; isPending: boolean }>({
    timer: null,
    isPending: false,
  });
  const isCallable = computed(() => isVisible.value && !expiredTimeInfo.value.isPending);

  const tokenInfo = ref<Token>({
    refreshToken: '',
    accessToken: '',
  });
  const isPasswordChange = ref(false);
  const isPasswordExpired = ref(false);
  const passwordExpireDay = ref(0);
  const accessTokenExpiredTime = ref(-1);

  const initAuth = () => {
    tokenInfo.value = {
      refreshToken: '',
      accessToken: '',
    };
    isPasswordChange.value = false;
    isPasswordExpired.value = false;
    passwordExpireDay.value = 0;
  };

  const setStorage = () => {
    const { accessToken, refreshToken } = tokenInfo.value;

    webStorageController.setItem({
      type: 'session',
      key: STORAGE_KEY.TOKEN,
      value: encrypt.encode(
        JSON.stringify({
          accessToken,
          refreshToken,
          expiredTime: accessTokenExpiredTime.value,
        }),
      ),
    });
  };

  const logout = () => {
    initAuth();
    webStorageController.clear('session');
  };

  type LoginData = Token &
    UserLoginV6Response & { isPasswordExpired: boolean; passwordExpireDay: number };
  const login = async (loginData: { id: string; password: string }) => {
    const publicRsaKey = await getPublicRsaKey();
    const loginDataIdKey = checkValid(REG_EXP.EMAIL, loginData.id) ? 'email' : 'activeId';
    const encryptedLoginData = {
      [loginDataIdKey]: loginData.id,
      password: getEncryptString(loginData.password, publicRsaKey) as string,
    };
    const { data } = await loginUserV6ControllerAxios({
      request: encryptedLoginData,
    });
    // TODO api 응답 모델하고 전혀 다른 데이터가 응답되고 있음, BE팀 사정으로 model update 보류 요청, 임시로 강제 형변환으로 사용
    const loginResponse = data as LoginData;
    if (!loginResponse) return;
    const {
      accessToken,
      refreshToken,
      passwordChange,
      isPasswordExpired: pwExpired,
      passwordExpireDay: pwExpireDay,
    } = loginResponse;
    tokenInfo.value.accessToken = accessToken;
    tokenInfo.value.refreshToken = refreshToken;
    if (passwordChange === false && pwExpired === false) {
      accessTokenExpiredTime.value = getAccessTokenExpiredTime();
      await setTokenInHeader(accessToken);
      setStorage();
    }
    isPasswordChange.value = !!passwordChange;
    isPasswordExpired.value = pwExpired;
    passwordExpireDay.value = pwExpireDay;
  };

  const ssoLogin = async (id?: string) => {
    const response = await axios.get(`/api/v6/sso/${id}`, {
      responseType: 'json',
    });
    const loginResponse = response.data;
    if (!loginResponse) return;
    const { accessToken, refreshToken } = loginResponse;
    tokenInfo.value.accessToken = accessToken;
    tokenInfo.value.refreshToken = refreshToken;
    accessTokenExpiredTime.value = getAccessTokenExpiredTime();
    await setTokenInHeader(accessToken);
    setStorage();
  };

  const updateToken = async () => {
    if (!tokenInfo.value.refreshToken) {
      return;
    }
    try {
      const { data } = await postV1RefreshTokenAxios(tokenInfo.value);

      tokenInfo.value.accessToken = data.accessToken;
      if (data.refreshToken) {
        tokenInfo.value.refreshToken = data.refreshToken;
      }
      accessTokenExpiredTime.value = getAccessTokenExpiredTime();

      setStorage();
    } catch (e) {
      initAuth();
      console.log(e);
    }
  };

  const clearCheckTime = () => {
    if (expiredTimeInfo.value.timer) {
      clearTimeout(expiredTimeInfo.value.timer);
    }
  };
  const checkTimeForRefreshToken = async () => {
    const now = +dayjs();
    if (!accessTokenExpiredTime.value || dayjs(now).diff(accessTokenExpiredTime.value) > 0) {
      expiredTimeInfo.value.isPending = true;
      await updateToken();
      expiredTimeInfo.value.isPending = false;
    }
    expiredTimeInfo.value.timer = setTimeout(checkTimeForRefreshToken, TOKEN_CHECK_CYCLE_SEC);
  };

  const getToken = (type: TokenType) => tokenInfo.value[type];

  const setInitialInfo = async () => {
    clearCheckTime();

    if (tokenInfo.value.accessToken) {
      await checkTimeForRefreshToken();
      return;
    }

    const storedVal = webStorageController.getItem({ type: 'session', key: STORAGE_KEY.TOKEN });
    const tokenObj = encrypt.decode(storedVal, true);

    if (tokenObj) {
      const { accessToken, refreshToken, expiredTime } = tokenObj;
      tokenInfo.value = {
        ...tokenInfo.value,
        accessToken,
        refreshToken,
      };

      if (expiredTime) {
        accessTokenExpiredTime.value = expiredTime;
      }
      await setTokenInHeader(accessToken);
      await checkTimeForRefreshToken();
    }
  };
  document.addEventListener('visibilitychange', async () => {
    const { visibilityState } = document;
    const currentPath = router.currentRoute.value?.path;
    if (visibilityState === 'visible' && currentPath !== '/') {
      isVisible.value = true;
      clearCheckTime();
      await checkTimeForRefreshToken();
    } else if (visibilityState === 'hidden') {
      isVisible.value = false;
      clearCheckTime();
    }
  });

  return {
    isCallable,
    tokenInfo,
    passwordExpireDay,
    isPasswordChange,
    isPasswordExpired,
    logout,
    login,
    getToken,
    updateToken,
    setInitialInfo,
    checkTimeForRefreshToken,
    ssoLogin,
  };
});
