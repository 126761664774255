import { ref, computed } from 'vue';
import { VIEW_MODE } from '@/common/define/common.define';
import { defineStore } from 'pinia';

export type ViewMode = (typeof VIEW_MODE)[keyof typeof VIEW_MODE];

export const useViewModeStore = defineStore('viewMode', () => {
  const viewMode = ref<ViewMode>(VIEW_MODE.EXEMONE);
  const productName = computed(() => {
    if (viewMode.value === VIEW_MODE.EXEMONE) return 'exemONE';
    if (viewMode.value === VIEW_MODE.MAXGAUGE) return 'MaxGauge';
    if (viewMode.value === VIEW_MODE.INTERMAX) return 'Intermax';
    if (viewMode.value === VIEW_MODE.CLOUDMOA) return 'CloudMOA';
    return 'exemONE';
  });
  const isMaxgaugeMode = computed(() => viewMode.value === VIEW_MODE.MAXGAUGE);

  const setViewMode = (mode?: ViewMode) => {
    viewMode.value = mode ?? VIEW_MODE.EXEMONE;
  };

  return {
    viewMode,
    productName,
    isMaxgaugeMode,
    setViewMode,
  };
});
