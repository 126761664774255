<script setup lang="ts">
import { useInternational } from '@/common/locale';
import { getVersion } from '@/common/utils/commonUtils';

const { t } = useInternational();
const versionInfo = {
  version: getVersion(),
};
</script>

<template>
  <li class="module-info">
    <div class="module-info__title">
      <span>{{ t('WORD.GNB.HELP.MODULE_VERSION') }} : </span>
    </div>
    <div class="module-info__list">
      <div class="module-info__item-group">
        <span>{{ t('WORD.GNB.HELP.SYSTEM_MODULE_VERSION', versionInfo) }}</span>
        <span>{{ t('WORD.GNB.HELP.K8S_MODULE_VERSION', versionInfo) }}</span>
        <span>{{ t('WORD.GNB.HELP.APPLICATION_MODULE_VERSION', versionInfo) }}</span>
        <span>{{ t('WORD.GNB.HELP.DATABASE_MODULE_VERSION', versionInfo) }}</span>
      </div>
    </div>
  </li>
</template>

<style scoped lang="scss">
.module-info {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 8px 12px;

  &__list {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
  }

  &__item-group {
    display: flex;
    flex-direction: column;
    gap: 6px;
    user-select: text;
  }

  span {
    white-space: nowrap;
  }
}
</style>
