import { WidgetChartData, WidgetChartType, WidgetCreateType } from '@/dashboard/utils/types';
import { hasValidTargets, filterChartDataByValidity, isChartDataValid } from './widgets.utils';

export const ChartDataFilter: Record<
  Exclude<WidgetChartType, 'TEXT' | 'IMAGE' | 'GROUP' | 'HORIZONTAL_FILTER' | 'VERTICAL_FILTER'>,
  (createType: WidgetCreateType) => (chartData: WidgetChartData) => boolean
> = {
  // graphs widget
  TIME_SERIES: filterChartDataByValidity,
  SCATTER: filterChartDataByValidity,
  ONE_DAY_COMPARE: filterChartDataByValidity,
  EQUALIZER: filterChartDataByValidity,
  SCOREBOARD: filterChartDataByValidity,
  PIE: filterChartDataByValidity,
  STACKED_PROPORTION_BAR: filterChartDataByValidity,
  ACTION_VIEW: isChartDataValid,
  // list widget
  TABLE: filterChartDataByValidity,
  TREE_GRID: filterChartDataByValidity,
  TOP_LIST: filterChartDataByValidity,
  // architecture widget
  EXCLUSIVE: isChartDataValid,
  STATUS: hasValidTargets,
  STATUS_SUMMARY: isChartDataValid,
  TOPOLOGY: hasValidTargets,
  SERVER: hasValidTargets,
  // business widget
  SERVICE_CARD: hasValidTargets,
} as const;
