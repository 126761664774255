<script setup lang="ts">
import { computed, onBeforeMount, watch } from 'vue';
import { store } from '@/common/store';
import ReloadPrompt from '@/application/components/pwa/ReloadPrompt.vue';
import { getVersion } from '@/common/utils/commonUtils';
import { MyInfo } from '@/common/api/resTypes/userInfoRes';
import { PRODUCT_THEMES } from '@/common/utils';
import { setUserLanguage } from '@/common/locale';
import { webStorageController } from '@/common/utils/webStorage.util';
import ApiTraceWindow from '@/common/components/organisms/apiTraceWindow/ApiTraceWindow.vue';
import { useApiTraceEvent } from '@/common/components/organisms/apiTraceWindow/apiTraceWindow.uses';
import { useViewModeStore, ViewMode } from '@/common/stores/view-mode';
import { storeToRefs } from 'pinia';
import { getServerPropertyModeControllerAxios } from '@/openapi/secure/api/mode-controller-api';
import LoadingScreen from '@/common/components/organisms/loading/LoadingScreen.vue';
import { VIEW_MODE } from '@/common/define/common.define';
import { useMessageEventInMainThread } from '@/common/utils/windowMessage.util';
import OnSiteAlarm from '@/common/components/organisms/onSiteAlarm/OnSiteAlarm.vue';
import { useRtmApi } from './common/utils/apiUtils';
import { useViewManagerStore } from './common/stores/view-manager';
import { PropertyType, usePropertyStore } from './common/stores/property';

const { callApi } = useRtmApi();
const { setViewMode } = useViewModeStore();
const { propertyData } = storeToRefs(usePropertyStore());
const { productName, viewMode } = storeToRefs(useViewModeStore());
const { isLoadingRouterForEach } = storeToRefs(useViewManagerStore());

const FRONT_VERSION = getVersion('front');
console.info(`%c [FrontVersion] ${FRONT_VERSION}`, 'color: green');

const { isShow: isShowApiTraceWindow } = useApiTraceEvent();
useMessageEventInMainThread();

const userInfo = computed<MyInfo>(() => store.getters['myInfo/getAccountInfo']);

const setMetaTitle = () => {
  const metaTitle = document.querySelector('title') as HTMLTitleElement;
  let title: string;
  const hostName = location.hostname;
  if (hostName === '10.10.43.81' || hostName === 'dev.one.exem.xyz') {
    title = `${productName.value}_dev`;
  } else if (hostName === '10.10.36.246' || hostName === 'stg.one.exem.xyz') {
    title = `${productName.value}_staging`;
  } else {
    title = `${productName.value}`;
  }
  metaTitle.textContent = `${title}`;
};

const setMetaFavicon = () => {
  const faviconLink = document.querySelector("link[rel='icon']") as HTMLLinkElement;
  faviconLink.href = `/favicon_${viewMode.value}.svg`;
};

const setMetaInfo = () => {
  setMetaTitle();
  setMetaFavicon();
};

const initProperty = async () => {
  const { data, error } = await callApi({
    fn: getServerPropertyModeControllerAxios,
  });

  if (error) {
    console.log(error);
    return;
  }

  propertyData.value = data![0] as PropertyType;

  let responseViewMode = data?.[0]?.viewMode?.toLocaleLowerCase() || VIEW_MODE.EXEMONE;

  // TEMP: maxgauge 모드 원활한 테스트를 위한 코드
  const sessionStorageViewMode = webStorageController.getItem({
    type: 'session',
    key: 'viewmode',
  });

  if (sessionStorageViewMode === VIEW_MODE.MAXGAUGE) {
    responseViewMode = sessionStorageViewMode;
  }

  setViewMode(responseViewMode as ViewMode);
  document.body.classList.add(`view-mode-${responseViewMode}`);

  setMetaInfo();
};

watch(
  () => [userInfo.value.theme, userInfo.value.language],
  () => {
    const rootEl = document.documentElement;
    const userTheme = userInfo.value?.theme?.toLowerCase() ?? '';
    rootEl.dataset.theme = PRODUCT_THEMES.includes(userTheme) ? userTheme : 'light';
    rootEl.dataset.language = userInfo.value?.language ?? 'EN';
  },
  { immediate: true },
);

onBeforeMount(async () => {
  const storedLanguage = webStorageController.getItem({
    type: 'local',
    key: 'language',
  });

  if (storedLanguage) {
    setUserLanguage(storedLanguage);
  }

  await initProperty();

  // TEMP: maxgauge 모드 원활한 테스트를 위한 코드
  const urlSearch = new URLSearchParams(location.search);
  const urlViewMode = urlSearch.get('viewmode');

  if (urlViewMode) {
    if (urlViewMode === VIEW_MODE.MAXGAUGE) {
      webStorageController.setItem({
        type: 'session',
        key: 'viewmode',
        value: urlViewMode,
      });

      setViewMode(VIEW_MODE.MAXGAUGE);
      document.body.className = `view-mode-${urlViewMode}`;
    } else {
      webStorageController.removeItem({
        type: 'session',
        key: 'viewmode',
      });

      setViewMode(VIEW_MODE.EXEMONE);
      document.body.className = `view-mode-${VIEW_MODE.EXEMONE}`;
    }
    setMetaInfo();
  }
});
</script>

<template>
  <router-view v-slot="{ Component }">
    <loading-screen v-if="isLoadingRouterForEach" />
    <component
      :is="Component"
      v-else
    />
  </router-view>
  <on-site-alarm />
  <api-trace-window v-model:is-show="isShowApiTraceWindow" />
  <reload-prompt />
</template>
