import { getCurrentInstance, ref } from 'vue';
import { isEventType } from '@/infrastructure/views/networkDeviceView/networkDeviceDetail/networkDeviceAlert/networkDeviceAlert.setup';
import { getDeviceAlertMessageNdmMonitoringControllerAxios } from '@/openapi/ndm/api/ndm-monitoring-controller-api';
import { showErrorMsg } from '@/common/utils/commonUtils';
import { useInternational } from '@/common/locale';
import { SlideTabPanelInfo, useSlideDetailStore } from '@/common/stores/slide-detail';
import { TOTAL_DETAIL } from '@/common/stores/slide-detail.logic';
import { columnFormatter, getAlertType } from '@/alert/utils/utils';
import {
  tagsAndTargetsController,
  TARGET_TYPE,
  useAlertTagsAndTargets,
} from '@/alert/utils/tagsAndTargets.uses';
import { getRuleNameIcon } from '@/alert/components/alertGrid/alertGrid.utils';
import { ExtractedCustomColumnTreeData } from '@/common/utils';
import {
  FIXED_WIDTH_ALERT_COLUMNS,
  SEARCHABLE_ALERT_COLUMNS,
} from '@/alert/components/alertGrid/alertGrid.define';
import { CHECK_BY_COMBINATION, CHECK_BY_TARGET } from '@/alert/utils/define';

interface EventAlertInfo {
  messageText: string;
  networkInfo: Record<string, string>;
}

export const useEventTypeValue = () => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();

  const isShowMessageWindow = ref(false);
  const eventAlertInfo = ref<EventAlertInfo>({
    messageText: '',
    networkInfo: {},
  });

  const setEventInfo = (eventType, data) => {
    if (eventType.toLowerCase() === 'syslog') {
      const { facility, severity, message } = data.sysLogMessage;
      eventAlertInfo.value = {
        messageText: message,
        networkInfo: { facility, severity },
      };
    } else if (eventType.toLowerCase() === 'trap') {
      const { genericTrapType, specificTrapCode, enterpriseOid, message } = data.snmpTrapMessage;
      eventAlertInfo.value = {
        messageText: message,
        networkInfo: { genericTrapType, specificTrapCode, enterpriseOid },
      };
    }
  };

  const onClickValueCell = async ({ value, row, ruleId }) => {
    if (!isEventType(value)) {
      return;
    }
    const eventType = row.value || value;
    const deviceId = row.targets[0]?.id;
    const alertRuleId = row.ruleId || ruleId;

    if (!deviceId) {
      showErrorMsg(ctx, t('MESSAGE.NO_DATA'));
      return;
    }

    isShowMessageWindow.value = true;

    try {
      const { data } = await getDeviceAlertMessageNdmMonitoringControllerAxios({
        alertRuleId,
        deviceId,
        eventType,
      });
      setEventInfo(eventType, data.data?.[0]);
    } catch (e: any) {
      if (e?.response?.status && e.response.status !== 200) {
        showErrorMsg(ctx, t('MESSAGE.FAILED'));
      }
    }
  };

  return {
    isShowMessageWindow,
    eventAlertInfo,
    onClickValueCell,
  };
};

export const useAlertRuleDetail = () => {
  const isAlertRuleDetailShow = ref(false);

  const { addTabPanel } = useSlideDetailStore();
  const showAlertRuleDetail = ({
    info,
    init = false,
    state = {},
  }: {
    info: { ruleName: string; ruleType: string; alertRuleId?: string | null };
    init?: boolean;
    state?: SlideTabPanelInfo['state'];
  }) => {
    const { ruleName, ruleType, alertRuleId } = info;
    addTabPanel(
      {
        type: TOTAL_DETAIL.ALERT,
        name: ruleName,
        addition: {
          ruleId: alertRuleId,
          type: getAlertType(ruleType),
        },
        init,
      },
      state,
    );
    isAlertRuleDetailShow.value = true;
  };

  return {
    isAlertRuleDetailShow,
    showAlertRuleDetail,
  };
};

export type AlertTreeRow = ExtractedCustomColumnTreeData<typeof FIXED_WIDTH_ALERT_COLUMNS>;
export type AlertRowFormatter<Row = any> = Record<string, (val: any, row: Row) => any>;

export const useAlertTreeGrid = ({
  columnOption,
}: {
  columnOption: 'fixedWidth' | 'searchable';
}) => {
  const alertColumns =
    columnOption === 'fixedWidth' ? FIXED_WIDTH_ALERT_COLUMNS : SEARCHABLE_ALERT_COLUMNS;

  const { makeAlertTreeRows: makeRows } = useAlertTagsAndTargets();
  const makeAlertTreeRows = <Row extends { alertValue?: any; ruleCriteria?: string }>({
    data,
    formatter = {},
  }: {
    data: Row[];
    formatter?: AlertRowFormatter;
  }) =>
    makeRows({
      data,
      columns: alertColumns,
      targetType: TARGET_TYPE.NONE,
      formatter: {
        ruleNameIcon: (_, { ruleCriteria }) => ({
          iconClass: getRuleNameIcon({ ruleCriteria: ruleCriteria || CHECK_BY_TARGET }),
          tooltip: ruleCriteria || CHECK_BY_TARGET,
        }),
        targets: (_, { ruleCriteria, ruleType, targetId, targetName, category, target }) => {
          // by target
          if (ruleCriteria === CHECK_BY_TARGET || getAlertType(ruleType) === 'system') {
            return tagsAndTargetsController[TARGET_TYPE.TARGETS]([
              { targetId, targetName, category },
            ]).targets;
          }

          // by combination 부모
          if (ruleCriteria === CHECK_BY_COMBINATION) {
            return tagsAndTargetsController[TARGET_TYPE.TARGETS]([]).targets;
          }

          // by combination 자식
          return tagsAndTargetsController[TARGET_TYPE.TARGETS](target).targets;
        },
        value: (_, { alertValue }) => columnFormatter.value(alertValue),
        ...formatter,
      },
    });

  return {
    alertColumns,
    makeAlertTreeRows,
  };
};
