import { DashboardList } from '@/common/stores/dashboard-popup';
import { DashboardKey } from '@/common/components/molecules/DetailInformation.vue';

export interface Props {
  dashboardList: DashboardList[];
  moveDashBoard: (id: number) => void;
  keyInfo?: DashboardKey;
}

const setup = (props: Props) => {
  const onClickDashboardList = (list) => {
    if (list.dashboardId) {
      props.moveDashBoard(list.dashboardId);
    } else {
      location.replace(list.url);
    }
  };

  return {
    onClickDashboardList,
  };
};

export { setup };
