<script setup lang="ts">
import { setup } from '@/common/components/organisms/baseMenu/baseMenu.setup';
import { clickoutside as vClickOutside } from '@/common/directives/clickoutside';
import SimpleTextTooltip from '@/common/components/molecules/simpleTextTooltip/SimpleTextTooltip.vue';
import TutorialGuide from '@/help/components/tutorialGuide/TutorialGuide.vue';
import MyInfoPop from './myInfoPop/MyInfoPop.vue';
import MenuElement from './menuElement/MenuElement.vue';
import UserMenuElement from './menuElement/UserMenuElement.vue';
import FloatingMenu from './floatingMenu/FloatingMenu.vue';
import SupportMenuElement from './menuElement/SupportMenuElement.vue';
import RightPanelMenu from './rightPanelMenu/RightPanelMenu.vue';
import { BASE_MENU_VALUE } from './baseMenu.define';
import UserSupportPopup from './userSupportPopup/UserSupportPopup.vue';

const {
  t,

  menuInfo,
  dashboardMenuInfo,
  analysisBoardMenuInfo,
  myInfoMenuInfo,
  helpMenuInfo,

  productName,

  expandedMenu,
  hoveredMenuInfo,
  isMyInfoPopShown,

  floatingMenuRef,
  baseMenuRef,
  tooltipMouseEvent,
  isBaseMenuHidden,

  onMouseEventInTarget,
  onHoverMenu,
  onExpandMenu,
  onExpandMyInfoMenu,
  onExpandSupportMenu,
  onShrinkMenu,
  onHideMenu,
  onHideMyInfoPop,
  onClickTitle,
  onClickSetting,
} = setup();
</script>

<template>
  <aside
    class="base-menu-wrapper"
    @mouseleave="onHideMenu"
  >
    <nav
      ref="baseMenuRef"
      :class="['base-menu', { expanded: expandedMenu.expanded, hidden: isBaseMenuHidden }]"
    >
      <div class="base-menu-header">
        <h1
          class="base-menu-header__logo"
          @click="onClickTitle"
        >
          {{ productName }}
        </h1>
        <div
          class="base-menu-header__text"
          @click="onClickTitle"
        />
        <button
          class="base-menu-header__slide-button"
          @click="onShrinkMenu"
          @mouseenter="onMouseEventInTarget"
          @mouseleave="onMouseEventInTarget"
        >
          <ev-icon icon="icon-left-panel" />
        </button>
        <simple-text-tooltip
          :mouse-event="tooltipMouseEvent"
          base-pos="bottom"
          :text="`${t('WORD.HIDE_MENU')}`"
        />
      </div>
      <ul class="base-menu__top">
        <template
          v-for="(info, idx) in menuInfo"
          :key="`${info.text}__${idx}`"
        >
          <menu-element
            v-if="info.value !== BASE_MENU_VALUE.SETTINGS && info.value !== BASE_MENU_VALUE.HELP"
            :menu-info="info"
            :dashboard-menu-list="dashboardMenuInfo[info.value]"
            :analysis-board-menu-list="analysisBoardMenuInfo[info.value]"
            :selected="expandedMenu.menuValue === info.value"
            @click="() => onExpandMenu(info)"
            @hover="onHoverMenu"
          />
        </template>
      </ul>
      <ul class="base-menu__bottom">
        <li
          v-if="helpMenuInfo"
          class="base-menu__support-item"
          :data-menu="helpMenuInfo.value"
        >
          <support-menu-element
            :info="helpMenuInfo"
            :selected="expandedMenu.menuValue === helpMenuInfo.value"
            @hover="onHoverMenu"
            @click="onExpandSupportMenu"
          />
          <user-support-popup
            :class="[
              'support-popup',
              {
                expanded: expandedMenu.expanded,
              },
            ]"
          />
        </li>
        <li
          v-if="myInfoMenuInfo"
          class="base-menu__profile-item"
          :data-menu="myInfoMenuInfo.value"
        >
          <user-menu-element
            :info="myInfoMenuInfo"
            :selected="expandedMenu.menuValue === myInfoMenuInfo.value"
            @mouseenter="onHoverMenu"
            @click="onExpandMyInfoMenu"
          />
          <my-info-pop
            v-click-outside="onHideMyInfoPop"
            :class="[
              'base-menu__profile-item--pop',
              {
                expanded: expandedMenu.expanded,
                shown: isMyInfoPopShown,
              },
            ]"
            @click-setting="onClickSetting"
          />
        </li>
      </ul>
      <div
        :class="['base-sub-menu', { 'base-sub-menu--show': !!hoveredMenuInfo.menuInfo }]"
        :style="{
          top: `${hoveredMenuInfo.top}px`,
        }"
        @mouseleave="onHideMenu"
      >
        <floating-menu
          ref="floatingMenuRef"
          :menu-info="hoveredMenuInfo.menuInfo"
          :dashboard-menu-list="dashboardMenuInfo[hoveredMenuInfo.menuInfo?.value ?? '']"
          :analysis-board-menu-list="analysisBoardMenuInfo[hoveredMenuInfo.menuInfo?.value ?? '']"
        />
      </div>
    </nav>
    <right-panel-menu />
    <tutorial-guide />
  </aside>
</template>

<style lang="scss">
.base-menu-wrapper {
  display: flex;
  z-index: $on-base-sub-menu;
  flex-direction: row;
  height: 100%;
  background-color: var(--gnb-bg-color);
}
.base-menu {
  --app-list-padding-top: 6px;
  --shadow-color: rgba(0, 0, 0, 0.14);
  --tooltip-bg-color: #393939;

  display: flex;
  position: relative;
  flex-direction: column;
  width: var(--gnb-menu-width);
  height: 100%;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.2);
  transition: width $animate-faster;

  &__top {
    flex: 1;
    padding: var(--app-list-padding-top) 1.5px 0 5.5px;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 4px !important;
      background-color: var(--gnb-bg-color) !important;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--gnb-bg-color) !important;
    }

    &:not(:hover)::-webkit-scrollbar-thumb {
      background-color: var(--gnb-bg-color) !important;
    }
  }

  &__bottom {
    width: 100%;
    padding-bottom: 10px;
  }

  &__support-item {
    position: relative;
    width: 100%;

    &:hover {
      .support-popup:not(.expanded) {
        display: block !important;
        left: var(--gnb-menu-width);
      }
    }
  }

  &__profile-item {
    position: relative;
    width: 100%;

    &:hover {
      .base-menu__profile-item--pop:not(.expanded) {
        display: block !important;
        left: var(--gnb-menu-width);
      }
    }
  }

  > div:not(.base-sub-menu),
  > ul {
    z-index: $on-base-sub-menu;
    border-right: 1px solid var(--gnb-border-color);
    background-color: var(--gnb-bg-color);
  }

  &.expanded {
    width: var(--gnb-menu-width-expanded);

    .base-menu__bottom {
      border-top: 1px solid var(--gnb-border-color);
    }
  }

  &.hidden {
    width: 0;
    overflow: hidden;
  }
}
.base-sub-menu {
  --sub-menu-transform: calc(var(--gnb-menu-width) - var(--gnb-sub-menu-width) - 1px);

  position: absolute;
  top: 0;
  left: 0.3px;
  z-index: $on-base-sub-menu - 30;
  width: var(--gnb-sub-menu-width);
  max-height: calc(100vh - var(--gnb-header-height));
  background-color: var(--lnb-bg-color);
  box-shadow: var(--dropdown-shadow);
  transition: transform $animate-fast;
  transform: translateX(var(--sub-menu-transform));
  backdrop-filter: blur(10px);

  &--show {
    transform: translateX(var(--gnb-menu-width)) !important;
  }
}
</style>
