/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  TagsRequest,
  TargetByTagResponse,
  TargetResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getTargetKeyValueTagControllerAxios = ({
  categories,
  dashboardId,
  tagKind,
  targetIds,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  categories?: Array<string>;
  dashboardId?: number;
  tagKind?: 'all' | 'business_service' | 'tag' | 'tag_exclude_service';
  targetIds?: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TargetResponse> => {
  const localVarPath = '/api/v7/tags/target-tag-values';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      categories,
      dashboardId,
      tagKind,
      targetIds,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTargetIdsListByTagTagControllerAxios = ({
  requests,
  targetCategory,
  targetIds,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  requests: Array<TagsRequest>;
  targetCategory?: string;
  targetIds?: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TargetByTagResponse> => {
  const localVarPath = '/api/v7/tags/targets-by-tags';
  const httpMethod = 'post';

  const data: any = requests;
  const config: any = {
    params: {
      targetCategory,
      targetIds,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export { getTargetKeyValueTagControllerAxios, getTargetIdsListByTagTagControllerAxios };
