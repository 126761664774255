import { ref, watch, computed, onMounted } from 'vue';
import { useInternational } from '@/common/locale';
import router from '@/common/router';
import { useBaseMenuStore } from '@/common/stores/base-menu';
import { storeToRefs } from 'pinia';
import { getLocaleKey } from '@/common/utils/string.utils';
import { webStorageController } from '@/common/utils/webStorage.util';

export const setup = () => {
  const { t } = useInternational();
  const baseMenuStore = useBaseMenuStore();
  const { expandedMenu } = storeToRefs(baseMenuStore);
  const STORAGE_KEY = 'showTutorialTooltip';
  const tutorialViewItems = [
    'dashboard',
    'infrastructure',
    'kubernetes',
    'application',
    'pa',
    'database',
  ];
  const tutorialViewKey = ref<string>('');
  const tutorialInfo = computed(() => ({
    title: t('TUTORIAL.TOOLTIP.TITLE', {
      value: t(`WORD.GNB.${getLocaleKey(tutorialViewKey.value)}`),
    }),
    description:
      tutorialViewKey.value === 'dashboard'
        ? t('TUTORIAL.TOOLTIP.DESCRIPTION_DASHBOARD')
        : t('TUTORIAL.TOOLTIP.DESCRIPTION'),
  }));
  const showTooltip = ref(false);

  const goToTutorial = () => {
    router.push(`/tutorial/${tutorialViewKey.value}`);
  };

  const hideTooltip = () => {
    showTooltip.value = false;
  };

  const dismissTooltip = () => {
    webStorageController.setItem({
      type: 'local',
      key: STORAGE_KEY,
      value: 'hidden',
    });
    hideTooltip();
  };

  watch(
    () => expandedMenu.value.menuValue,
    (menuValue) => {
      const showTutorialTooltip = webStorageController.getItem({
        type: 'local',
        key: STORAGE_KEY,
      });
      if (
        !menuValue ||
        !tutorialViewItems.includes(menuValue) ||
        showTutorialTooltip === 'hidden'
      ) {
        showTooltip.value = false;
        tutorialViewKey.value = '';
      } else {
        showTooltip.value = true;
        tutorialViewKey.value = menuValue === 'pa' ? 'analysis' : menuValue;
      }
    },
    { immediate: true },
  );

  onMounted(() => {
    const { isFromTutorial } = history.state;

    if (isFromTutorial && showTooltip.value) {
      hideTooltip();
    }
  });

  return {
    t,
    tutorialInfo,
    showTooltip,
    goToTutorial,
    hideTooltip,
    dismissTooltip,
  };
};
